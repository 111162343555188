@import '@styles/mixins.style';

.acf-icon-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: var(--ra-spacing-4);

  @include breakpoint(md) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include breakpoint(xl) {
    margin-inline: var(--ra-spacing-8);
  }
}
