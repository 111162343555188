@import './breakpoints.style.scss';

@mixin breakpoint($class) {
  @if $class == xs {
    @media (min-width: $xs) {
      @content;
    }
  } @else if $class == sm {
    @media (min-width: $sm) {
      @content;
    }
  } @else if $class == md {
    @media (min-width: $md) {
      @content;
    }
  } @else if $class == lg {
    @media (min-width: $lg) {
      @content;
    }
  } @else if $class == xl {
    @media (min-width: $xl) {
      @content;
    }
  } @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg, xl";
  }
}

@mixin breakpoint-max($class) {
  @if $class == xs {
    @media (max-width: $xs) {
      @content;
    }
  } @else if $class == sm {
    @media (max-width: $sm) {
      @content;
    }
  } @else if $class == md {
    @media (max-width: $md) {
      @content;
    }
  } @else if $class == lg {
    @media (max-width: $lg) {
      @content;
    }
  } @else if $class == xl {
    @media (max-width: $xl) {
      @content;
    }
  } @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg, xl";
  }
}

@mixin breakpoint-minmax($class) {
  @if $class == xs {
    @media (max-width: $xs) {
      @content;
    }
  } @else if $class == sm {
    @media (min-width: $xs) and (max-width: $sm) {
      @content;
    }
  } @else if $class == md {
    @media (min-width: $sm) and (max-width: $md) {
      @content;
    }
  } @else if $class == lg {
    @media (min-width: $lg) and (max-width: $lg) {
      @content;
    }
  } @else if $class == xl {
    @media (min-width: $xl) and (max-width: $xl) {
      @content;
    }
  } @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg, xl";
  }
}

@mixin define-color($title, $color) {
  #{$title}: #{$color};
  #{$title}-rgb:
    #{red($color)},
    #{green($color)},
    #{blue($color)};
}

@function use-alpha($title, $alpha: 1) {
  @return rgba(var(#{$title}-rgb), $alpha);
}

@mixin line-clamp($lines, $line-height: 1.5) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical; /* number of lines to show */
  -webkit-line-clamp: $lines;
  line-clamp: $lines;
  text-overflow: ellipsis;

  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  position: relative;

  line-height: $line-height;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 !important;

  -webkit-line-clamp: $lines;
  height: (1em * $line-height * $lines);
}
