@import '@styles/mixins.style';

.ra-tabs {
  position: relative;
  &::after {
    @include breakpoint(sm) {
      position: absolute;
      content: '';
      left: 0;
      width: 100%;
      height: 2px;
      background: url('data:image/svg+xml;utf8,<svg height="2" width="20" xmlns="http://www.w3.org/2000/svg"><line x1="1" y1="1" x2="10" y2="1" stroke="rgba(0, 0, 0, 0.2)" strokeWidth="2"/></svg>');
      bottom: 4px;
    }
  }

  &--mobile {
    margin-top: -48px;
    margin-bottom: -24px;
    padding-bottom: 24px;
    width: 100%;
    overflow: hidden;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;

    &__button-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      max-width: 100%;
      margin-bottom: 6px;
      overflow-x: hidden;
    }

    &__button {
      opacity: 0;
      transition: all 0.35s ease-in-out;
      width: 0;

      &--left {
        transform: translate(-24px);
      }

      &--right {
        transform: translate(24px);
      }

      &--active {
        opacity: 1;
        transform: translate(0);
        width: 24px;
      }
    }

    &__tabs-wrapper {
      overflow-x: auto;
      width: max-content;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__tab-list {
      display: flex;
      overflow-x: auto;
      width: max-content;
      padding-bottom: 2px;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: var(--ra-spacing-2);
    }

    &__item {
      list-style-type: none;
      text-align: center;
      position: relative;
      min-width: 100px;
      display: flex;
      justify-content: center;
      svg {
        position: absolute;
        left: 50%;
        transform: translateX(-50%) scale(0.5);
        width: auto;
        bottom: -4px;
        text-align: center;
      }
    }

    @include breakpoint(sm) {
      display: none !important;
    }
  }
  &--desktop {
    display: none !important;
    @include breakpoint(sm) {
      position: relative;
      height: 40px;
      padding-block: var(--ra-spacing-3);
      display: flex !important;
      justify-content: center;
      align-items: center;
      gap: var(--ra-spacing-5);
    }
  }

  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--ra-spacing-4);
  }

  &__item {
    position: relative;
    margin-inline: var(--ra-spacing-4);

    button {
      outline: none;
      border: none;
      background-color: transparent;
      padding-inline: 0;

      &:hover {
        span {
          color: var(--color-primary) !important;
          cursor: pointer;
        }
      }

      &:focus-visible {
        span {
          outline: var(--ra-focus-outline);
          outline-color: var(--color-primary);
          outline-offset: 5px;
          border-radius: 2px;
        }
      }
    }

    svg {
      position: absolute;
      bottom: calc(0px - var(--ra-spacing-4));
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
