.ra-link {
  text-decoration: none;
  color: inherit;

  &:hover {
    cursor: pointer !important;
    text-decoration: underline;
    span {
      color: var(--color-primary) !important;
    }
  }

  &:focus-visible {
    outline: var(--ra-focus-outline);
    outline-color: var(--color-primary);
    outline-offset: 5px;
    border-radius: 2px;
  }

  &--remove-underline {
    &:hover {
      text-decoration: none;
    }
  }
}
